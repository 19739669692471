import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useContext, useMemo, useRef, useState } from 'react'

import AdminPostTagsFilter from '@/components/admin/posts/filters/AdminPostTagsFilter'
import UserSideBoardDescription from '@/components/posts/postList/UserSideBoardDescription'
import UserBoardList from '@/components/posts/UserBoardList'
import UserPostFilter from '@/components/posts/UserPostFilter'
import SearchInput from '@/components/shared/components/SearchInput'
import { EmbedNoShowWrapper } from '@/components/shared/components/wrapper/EmbedWrapper'
import { PAGINATION_LIMIT } from '@/config/appConstants'
import { POST_SEARCH_ATTRIBUTES_TO_RETRIVE } from '@/config/module/postsConstants'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import clsx from '@/lib/clsxm'
import {
  getPostFromLocalWithIDs,
  sortSearchedPosts,
  updateSearchedPosts,
} from '@/lib/helpers/modules/postHelper'
import { userSidePostListSearch } from '@/lib/helpers/modules/searchHelper'
import {
  embedHomePath,
  postWidgetPath,
  userSidePostSinglePath,
} from '@/lib/helpers/pathHelpers'
import { getVotesOfPosts } from '@/models/Post'
import FourNotFour from '@/pages/404'
import type { IBoardDetails } from '@/types/board'
import type {
  ICustomStatusKey,
  IOrganizationCustomization,
} from '@/types/organization'
import type { IPost, IVoteDataMap } from '@/types/post'
import type { ISearchResponse } from '@/types/search'
import type { IUserProfile } from '@/types/user'

import UserCustomLinksSection from '../shared/UserCustomLinksSection'
import UserPostList from './UserPostList'

const NewPostButton = dynamic(
  () => import('@/components/posts/newPost/NewPostButton'),
  { ssr: false }
)

interface IPropTypes {
  initialPosts: IPost[]
}

export default function UserPostListPage({ initialPosts }: IPropTypes) {
  const t = useTranslations()
  const [searchResults, setSearchResults] = useState<IPost[] | null>(null)
  const searchInputRef = useRef<HTMLInputElement>(null)
  const {
    buckets,
    userProfile,
    organization,
    organizationCustomization,
    organizationSetting,
    embedType,
  } = useContext(HNContext)
  const router = useRouter()
  const { query } = router
  const { hide_boards: hideBoards } = query
  const { boardSlug, bucket_id, sort } = router.query
  const [searchPage, setSearchPage] = useState<number>(1)
  const searchSort = useMemo(() => {
    const currentSort = sort || organizationCustomization?.default_sort
    if (!currentSort || currentSort === 'latest') return 'id:asc'
    if (currentSort === 'top') return 'votes_count:desc'
    return undefined
  }, [sort, organizationCustomization?.default_sort, boardSlug])
  const [searchedPostsEstimatedCount, setSearchedPostsEstimatedCount] =
    useState<number>(0)

  const board = useMemo(() => {
    if (!buckets) return undefined
    return buckets?.find((b) => b.name === boardSlug)
  }, [buckets, boardSlug])

  const activeBoard = useMemo(() => {
    if (boardSlug) return buckets?.find((bucket) => bucket.name === boardSlug)
    if (bucket_id)
      return buckets?.find((bucket) => bucket?.id?.toString() === bucket_id)
    return board
  }, [buckets, boardSlug, bucket_id])

  const handleSinglePostRedirect = (post: IPost) => {
    if (router.asPath.includes(postWidgetPath)) {
      router.push(`/postwidget/p/${post.slug}`)
    } else if (router.asPath.includes(embedHomePath)) {
      router.push(`/embed/home/p/${post.slug}`)
    } else {
      router.push(userSidePostSinglePath(post.slug))
    }
    return true
  }

  const handleSearchResponses = (data: ISearchResponse[]) => {
    const indexResponses = data.find(
      (result) => result.indexName === 'FeatureRequest'
    )
    // check if page is 1 and reset the search results
    const previousPosts =
      indexResponses?.offset === 0 ? [] : searchResults || []
    if (indexResponses?.offset === 0) setSearchPage(1)
    setSearchedPostsEstimatedCount(indexResponses?.estimatedTotalHits || 0)
    const sortedPosts =
      indexResponses?.hits && indexResponses?.query?.length
        ? sortSearchedPosts(
            [...previousPosts, ...indexResponses.hits] as IPost[],
            router.query
          )
        : null
    setSearchResults(sortedPosts)
    if (!indexResponses?.query?.length && searchInputRef.current) {
      searchInputRef.current.value = ''
      setSearchPage(1)
      setSearchResults(null)
    }

    if (sortedPosts) {
      const ids = sortedPosts.map((post) => post.id)

      getPostFromLocalWithIDs(ids)
        .then((votesData) => {
          // Push voting true to all posts
          const updatedVotesData = Object.fromEntries(
            Object.keys(votesData).map((key) => [
              key,
              { ...votesData[key], voting: true },
            ])
          )
          const localPosts = updateSearchedPosts(
            sortedPosts,
            updatedVotesData as IVoteDataMap
          )
          setSearchResults(localPosts)
        })
        .then(() => getVotesOfPosts(ids))
        .then((votes) => {
          const updatedPosts = updateSearchedPosts(sortedPosts, votes)
          setSearchResults(updatedPosts)
        })
    }
  }

  if (!board && router.pathname === '/b/[boardSlug]') return <FourNotFour />

  return (
    <>
      <div className='bg-gray2'>
        <section
          data-testid='user_post_list_page'
          className={'mx-auto max-w-7xl'}
        >
          <div className='grid grid-cols-1 space-x-0 px-2 md:grid-cols-9 md:space-x-5'>
            <div
              className={clsx('order-last md:order-first md:col-span-6', {
                'md:col-span-9': embedType === 'moduleEmbed' || !!hideBoards,
              })}
            >
              <div className='relative my-2 rounded-md border border-gray5 bg-snow p-4'>
                <div className='relative'>
                  <div className='flex max-h-[250px] flex-col overflow-y-auto'>
                    <h2 className='text-base font-semibold'>
                      {activeBoard ? activeBoard.display_name : 'All Posts'}
                    </h2>
                    <UserSideBoardDescription />
                    <div className='mt-1 block md:mt-0 md:hidden'>
                      <NewPostButton
                        boardId={activeBoard?.id}
                        onCreate={handleSinglePostRedirect}
                        testId='user_side_header_new_post_button'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex w-full items-center justify-between space-x-1 py-4'>
                <div className='flex items-center gap-1'>
                  <UserPostFilter
                    defaultSort={
                      (organizationCustomization as IOrganizationCustomization)
                        ?.default_sort
                    }
                  />

                  {!organizationSetting?.hide_tags && (
                    <AdminPostTagsFilter
                      size='sm'
                      filters={{ tag_id: router.query.tag_id, board: true }}
                      behaviour='filter'
                      onChange={(_key, value) => {
                        router.push({
                          pathname: router.pathname,
                          query: { ...router.query, tag_id: value },
                        })
                      }}
                      placeholder={t('common.selectTag')}
                      clearable
                      showBoardTags
                      loadFromCache
                      itemFilter={(item) => item.id !== 'untagged'}
                      selectProps={{
                        className: '!py-1 !px-2 !h-[28px]',
                      }}
                    />
                  )}
                </div>
                <div className='flex items-center justify-center gap-3'>
                  <SearchInput
                    inputRef={searchInputRef}
                    clearOnSelect
                    indexes={[
                      {
                        attributesToRetrieve: POST_SEARCH_ATTRIBUTES_TO_RETRIVE,
                        indexName: 'FeatureRequest',
                        label: 'Feature Request',
                        filters: userSidePostListSearch(
                          userProfile as IUserProfile,
                          board ? [board] : (buckets as IBoardDetails[]),
                          organization?.id as string,
                          {
                            status: router.query.status as ICustomStatusKey,
                          }
                        ),
                        sort: searchSort ? [searchSort] : [],
                      },
                    ]}
                    className={'block h-[28px] w-full shrink'}
                    inputProps={{
                      placeholder: t('posts.labels.searchForPosts'),
                      size: 'sm',
                      dataTestId: 'user_post_list_page_search_input',
                    }}
                    onSearch={handleSearchResponses}
                    page={searchPage}
                    hitsPerPage={PAGINATION_LIMIT.userSearchPostsList}
                    offset={
                      PAGINATION_LIMIT.userSearchPostsList * (searchPage - 1)
                    }
                  />
                  <div className='hidden md:block'>
                    <NewPostButton
                      boardId={activeBoard?.id}
                      onCreate={handleSinglePostRedirect}
                      testId='user_side_header_new_post_button'
                    />
                  </div>
                </div>
              </div>

              <div className='rounded-md border border-gray5 bg-snow'>
                <UserPostList
                  searchedPosts={searchResults}
                  initialPosts={initialPosts}
                  filters={{
                    sort: (
                      organizationCustomization as IOrganizationCustomization
                    )?.default_sort,
                  }}
                  onLoadMoreSearch={() =>
                    setSearchPage((prevPage) => prevPage + 1)
                  }
                  searchedPostsEstimatedCount={searchedPostsEstimatedCount}
                />
              </div>
            </div>
            {!hideBoards && (
              <EmbedNoShowWrapper>
                <div className='top-24 order-first block overflow-y-auto overflow-x-hidden py-1 md:sticky md:order-last md:col-span-3 md:h-[calc(100vh-100px)] md:min-h-[calc(100vh-100px)] md:pr-2'>
                  <UserBoardList />
                  <UserCustomLinksSection />
                </div>
              </EmbedNoShowWrapper>
            )}
          </div>
        </section>
      </div>
    </>
  )
}
