import React, { useContext } from 'react'

import Link from '@/components/shared/ui/Links/UnderlineLink'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import type { IOrganizationCustomization } from '@/types/organization'

export default function UserCustomLinksSection() {
  const t = useTranslations('common.labels.customLinks')
  const { organizationCustomization } = useContext(HNContext) as {
    organizationCustomization: IOrganizationCustomization
  }

  const renderURL = (key: keyof IOrganizationCustomization, title: string) => {
    if (!organizationCustomization) return <></>
    const url = organizationCustomization[key] as string
    if (!url) return <></>
    return (
      <Link
        href={url}
        target='_blank'
        rel='noreferrer'
        className='border-b border-gray5 p-1 text-xs font-normal text-gray12 hover:border-primary hover:text-primary'
      >
        {title}
      </Link>
    )
  }

  return (
    <div className='hidden md:block'>
      <div className='mt-3 grid grid-cols-2 gap-1 empty:hidden'>
        {renderURL('custom_about_us_url', t('about_us'))}
        {renderURL('custom_contact_us_url', t('contact_us'))}
        {renderURL('custom_privacy_policy_url', t('privacy_policy'))}
        {renderURL('custom_support_url', t('support'))}
        {renderURL('website_url', t('website'))}
      </div>
    </div>
  )
}
