import React, { useContext } from 'react'

import NextImage from '@/components/shared/ui/Image/NextImage'
import { settingsHelpLinks } from '@/config/staticURLs'
import HNContext from '@/context/HNContext'
import type { IOrganizationSetting } from '@/types/organization'

export default function PatreonButton() {
  const { organizationSetting } = useContext(HNContext) as {
    organizationSetting: IOrganizationSetting
  }

  if (
    !organizationSetting?.patreon_username ||
    organizationSetting?.patreon_username.length <= 0
  )
    return <></>

  return (
    <a
      href={`https://www.patreon.com/${organizationSetting.patreon_username}`}
      target='_blank'
      rel='noreferrer noopener'
    >
      <NextImage
        src={`${settingsHelpLinks.cdnLogo}become_patron.png`}
        className='mx-auto my-3 block w-40'
        alt='Patreon'
        height='38'
        width='160'
      />
    </a>
  )
}
