import { CardsThree, Lock, Plus } from '@phosphor-icons/react'
import dynamic from 'next/dynamic'
import type { ReactNode } from 'react'
import React, { useContext } from 'react'

import UserBoardListItem from '@/components/posts/UserBoardListItem'
import Badge from '@/components/shared/ui/Badge'
import Button from '@/components/shared/ui/Button'
import PatreonButton from '@/components/users/shared/PatreonButton'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import type { IBoardDetails } from '@/types/board'

const NewPostButton = dynamic(
  () => import('@/components/posts/newPost/NewPostButton'),
  { ssr: false }
)

export default function UserBoardList() {
  const t = useTranslations()
  const { buckets, organizationSetting } = useContext(HNContext)

  const renderBoardItem = (board: IBoardDetails) => (
    <div
      className='hn-animate-duration group flex max-w-full items-center justify-between gap-1 rounded-lg px-2 py-1.5 hover:bg-snow'
      key={board.id}
    >
      <UserBoardListItem board={board} />
      <div className='flex gap-1'>
        <Badge value={board.feature_requests_count} rounded size='xxs' />
        <NewPostButton boardId={board.id}>
          <Button fab variant='secondary' as='span' size='xs' icon={<Plus />} />
        </NewPostButton>
      </div>
    </div>
  )

  const renderPublicBoards = (): ReactNode => {
    const boards = buckets?.filter((b) => !b.private)
    if (boards?.length) {
      return (
        <div className='rounded-md bg-gray2'>
          <div className='mb-1 flex items-center justify-start gap-x-1 border-b border-gray5 py-1 font-medium text-gray12/80'>
            <CardsThree className='h-4 w-4' />
            <div className='text-sm'>
              {organizationSetting?.bucket_plural_name}
            </div>
          </div>
          <div>{boards.map(renderBoardItem)}</div>
        </div>
      )
    }
    return <></>
  }

  const renderPrivateBoards = (): ReactNode => {
    const boards = buckets?.filter((b) => b.private)
    if (boards?.length) {
      return (
        <div className='rounded-md bg-gray2'>
          <div className='mb-1 flex items-center justify-start gap-x-1 border-b border-gray5 py-1 font-medium text-gray12/80'>
            <Lock className='h-4 w-4' />

            {`${t('common.modules.boards.private.title')}`}
          </div>

          <div>{boards.map(renderBoardItem)}</div>
        </div>
      )
    }
    return <></>
  }
  return (
    <div className='flex flex-col gap-4'>
      <div className='hidden flex-col gap-3 md:flex'>
        {renderPublicBoards()} {renderPrivateBoards()}
        <PatreonButton />
      </div>
    </div>
  )
}
