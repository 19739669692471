import React, { useMemo } from 'react'

import Skeleton from '@/components/shared/ui/Skeleton/Skeleton'

interface IPropTypes {
  count?: number
}
export default function UserPostListLoader({ count = 10 }: IPropTypes) {
  const mockPosts = useMemo(
    () => Array.from({ length: count }, (_, i) => i),
    [count]
  )

  return (
    <div className='flex flex-col'>
      {mockPosts.map((p) => (
        <div
          key={p}
          className='flex space-x-3 border border-t-0 border-gray5 bg-snow px-3 py-4 dark:bg-gray3'
        >
          <Skeleton className='h-8 w-8 rounded-lg' />
          <div className='w-full'>
            <Skeleton className='mb-2 h-6 w-full rounded-lg' />
            <Skeleton className='h-4 w-full rounded-lg' />
            <div className='mt-2 flex space-x-2'>
              <Skeleton className='h-4 w-20 rounded-lg' />
              <Skeleton className='h-4 w-20 rounded-lg' />
              <Skeleton className='h-4 w-20 rounded-lg' />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
