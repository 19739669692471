import {
  ArrowCircleRight,
  CaretDown,
  Clock,
  ThumbsUp,
  TrendUp,
} from '@phosphor-icons/react'
import { useRouter } from 'next/router'
import React, { useContext, useMemo } from 'react'

import { STATUS_COLORS_MAP } from '@/config/appConstants'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import {
  arrayToObject,
  objectHasProperty,
  removeNullOrUndefinedKeys,
} from '@/lib/helpers/dataHelpers'
import type { IKeyValueMap, IKeyValuePair } from '@/types/common'

import Button from '../shared/ui/Button'
import Dropdown from '../shared/ui/Dropdown'
import {
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from '../shared/ui/Dropdown/Dropdown'
import ClosedIcon from './statusIcons/ClosedIcon'
import CompletedIcon from './statusIcons/CompletedIcon'
import InProgressIcon from './statusIcons/InProgressIcon'
import PlannedIcon from './statusIcons/PlannedIcon'
import ReviewIcon from './statusIcons/ReviewIcon'

const statusIcons: Record<string, any> = {
  under_review: ReviewIcon,
  planned: PlannedIcon,
  in_progress: InProgressIcon,
  completed: CompletedIcon,
  closed: ClosedIcon,
}

interface IFilterKeys extends IKeyValuePair {
  key: string
  Icon?: any
  iconColorClass?: string
}
interface IProptypes {
  defaultSort: string
}
export default function UserPostFilter({ defaultSort = 'all' }: IProptypes) {
  const t = useTranslations()
  const router = useRouter()
  const { customStatus, userProfile } = useContext(HNContext)
  const { sort, status, voted_by } = router.query as {
    sort: string
    status: string
    voted_by: string
  }

  const options: IFilterKeys[] = useMemo(() => {
    if (!customStatus) return []
    return [
      {
        label: t('posts.filters.latest'),
        value: 'latest',
        key: 'sort',
        Icon: Clock,
      },
      {
        label: t('posts.filters.top'),
        value: 'top',
        key: 'sort',
        Icon: TrendUp,
      },
      ...Object.keys(customStatus)
        .filter((key) => !['hidden', 'all'].includes(key))
        .map((statusKey: string) => {
          const Icon = statusIcons[statusKey] || InProgressIcon
          const colorClass = STATUS_COLORS_MAP[statusKey]

          return {
            label: customStatus[statusKey],
            value: [statusKey],
            key: 'status',
            Icon,
            iconColorClass: colorClass?.icon,
          }
        }),
      ...(userProfile
        ? [
            {
              label: t('posts.filters.votedByMe'),
              value: [userProfile.id],
              key: 'voted_by',
              Icon: ThumbsUp,
            },
          ]
        : []),
    ]
  }, [customStatus])

  const optionsMap: IKeyValueMap = useMemo<IKeyValueMap>(
    () => arrayToObject(options, 'value', 'label'),
    [options]
  )

  const handleFilter = (option: IFilterKeys) => {
    router.replace(
      {
        pathname: router.pathname,
        query: removeNullOrUndefinedKeys({
          ...router.query,
          sort: undefined,
          status: undefined,
          voted_by: undefined,
          [option.key]: option.value,
        }),
      },
      undefined,
      {
        shallow: true,
      }
    )
  }

  const renderLabel = () => {
    if (sort) return optionsMap[sort.toString()]
    if (status) return optionsMap[status.toString()]
    if (voted_by && objectHasProperty(optionsMap, voted_by))
      return optionsMap[voted_by]
    if (defaultSort && objectHasProperty(optionsMap, defaultSort))
      return optionsMap[defaultSort]
    return t('posts.filters.latest')
  }

  return (
    <Dropdown data-testid='user_post_filter'>
      <DropdownTrigger>
        <Button
          as='span'
          iconRight={<CaretDown />}
          size='xs'
          variant='outline'
          dataTestId='user_post_filter_button'
          className={'!bg-snow !text-gray10 dark:!bg-gray3'}
        >
          {renderLabel()}
        </Button>
      </DropdownTrigger>
      <DropdownContent align='start'>
        {options.map((option: IFilterKeys) => (
          <DropdownItem
            key={option.label}
            itemValue={option.label}
            icon={
              option.Icon ? (
                <option.Icon size={16} weight='bold' />
              ) : (
                <ArrowCircleRight size={16} />
              )
            }
            onSelect={() => handleFilter(option)}
          />
        ))}
      </DropdownContent>
    </Dropdown>
  )
}
