import React, { useContext } from 'react'

import HNContext from '@/context/HNContext'
import { userSideBoardSinglePath } from '@/lib/helpers/pathHelpers'
import type { IBoardDetails } from '@/types/board'

import { getBasePathPrefix } from '../../lib/helpers/urlHelpers'
import UnstyledLink from '../shared/ui/Links/UnstyledLink'

interface IPropTypes {
  board: IBoardDetails
}
export default function UserBoardListItem({ board }: IPropTypes) {
  const { embedType } = useContext(HNContext)

  return (
    <UnstyledLink
      href={userSideBoardSinglePath(board.name, getBasePathPrefix(embedType))}
      className=' truncate font-medium hover:no-underline'
    >
      {board.display_name}
    </UnstyledLink>
  )
}
