import { Binoculars } from '@phosphor-icons/react'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import UserPostItem from '@/components/posts/UserPostItem'
import PageTitle from '@/components/shared/common/PageTitle'
import InfiniteScroll from '@/components/shared/components/InfiniteLoaders'
import EmptyState from '@/components/shared/ui/EmptyState'
import LineHeader from '@/components/shared/ui/LineHeader'
import UserPostListLoader from '@/components/users/posts/UserPostListLoader'
import HNContext from '@/context/HNContext'
import type { IEntityUpdateEventData } from '@/lib/eventEmitter'
import { ENTITIES, EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import {
  removeDuplicatesInArray,
  removeKeyFromObject,
} from '@/lib/helpers/dataHelpers'
import {
  sortDBPosts,
  sortSearchedPosts,
} from '@/lib/helpers/modules/postHelper'
import { getOptimizedPosts, getPostFromLocalstorage } from '@/models/Post'
import type { IRootData } from '@/types/organization'
import type { IPost, IPostListAPIParams } from '@/types/post'

import { useTranslations } from '../../../hooks/useTranslations'

const NewPostButton = dynamic(
  () => import('@/components/posts/newPost/NewPostButton'),
  { ssr: false }
)

const POSTS_PER_PAGE = 15

interface IPropTypes {
  initialPosts: IPost[]
  searchedPosts?: IPost[] | null
  filters?: IPostListAPIParams
  emptyState?: React.ReactNode
  updateOnFilters?: boolean
  noFetch?: boolean
  onLoadMoreSearch?: () => any
  searchedPostsEstimatedCount?: number
}
export default function UserPostList({
  initialPosts,
  searchedPosts: _searchedPosts,
  filters: _filters,
  emptyState,
  updateOnFilters,
  noFetch = false,
  onLoadMoreSearch,
  searchedPostsEstimatedCount = 0,
}: IPropTypes) {
  const { organizationCustomization, buckets, organizationSetting } =
    useContext(HNContext) as IRootData

  const [posts, setPosts] = useState<IPost[]>(initialPosts || [])
  const [canFetchMore, setCanFetchMore] = useState(false)
  const [loading, setLoading] = useState(!initialPosts?.length)
  // use to check if there are any active api calls
  const [fetching, setFetching] = useState(false)
  const [searchedPosts, setSearchedPosts] = useState(_searchedPosts)
  const router = useRouter()
  const { sort } = router.query as { sort: string }
  const [filters, setFilters] = useState({
    page: 1,
    ..._filters,
    ...removeKeyFromObject(['boardSlug'], router.query),
  })
  const { boardSlug, bucket_id } = router.query as {
    boardSlug: string
    bucket_id: string
  }
  const t = useTranslations('posts')
  const controller = useRef(new AbortController())

  const pinnedPosts = useMemo(() => {
    return removeDuplicatesInArray(posts, 'id').filter((post) => post.pinned)
  }, [posts])

  const unPinnedPosts = useMemo(() => {
    return sortDBPosts(
      removeDuplicatesInArray(posts, 'id').filter((post) => !post.pinned),
      { sort: sort || organizationCustomization?.default_sort }
    )
  }, [posts])

  const activeBoard = useMemo(() => {
    let board
    if (boardSlug) board = buckets?.find((bucket) => bucket.name === boardSlug)
    if (bucket_id)
      board = buckets?.find((bucket) => bucket?.id?.toString() === bucket_id)
    return board
  }, [buckets, boardSlug, bucket_id])

  const handlePostsUpdate = (eventData: IEntityUpdateEventData): any => {
    const { actionType, entity, data } = eventData
    if (entity !== ENTITIES.POSTS) return
    const incomingPost = data.data
    if (actionType === EVENT_ACTION_TYPES.ADD) {
      setPosts((_posts) => {
        const isPresent = _posts.find(
          (p) => p.id.toString() === incomingPost.id.toString()
        )
        return isPresent ? _posts : [incomingPost, ..._posts]
      })
    }
    if (eventData.actionType === EVENT_ACTION_TYPES.UPDATE) {
      setPosts((_posts) =>
        _posts.map((post) => {
          if (post?.id?.toString() === incomingPost?.id?.toString())
            return { ...post, ...incomingPost }
          return post
        })
      )
    }
    if (actionType === EVENT_ACTION_TYPES.DELETE) {
      setPosts((_posts) => _posts.filter((post) => post.id !== incomingPost.id))
    }
  }

  const fetchPosts = () => {
    if (fetching && filters.page === 1) {
      controller.current.abort()
      controller.current = new AbortController()
    }
    const params = {
      sort: organizationCustomization?.default_sort,
      ...filters,
      bucket_slug: boardSlug,
      per_page: POSTS_PER_PAGE,
      hide_closed: !filters.status,
    }

    if (filters.page === 1) {
      setPosts([])
      setLoading(true)
      const localPosts = getPostFromLocalstorage(params)
      if (localPosts && localPosts.length) {
        setPosts(localPosts)
        setLoading(false)
      } else {
        setLoading(true)
      }
    }
    setFetching(true)
    getOptimizedPosts(params, {
      signal: controller.current.signal,
    })
      .then((newPosts) => {
        setCanFetchMore(newPosts.length >= POSTS_PER_PAGE)
        setPosts((oldPosts) =>
          filters.page === 1 ? newPosts : [...oldPosts, ...newPosts]
        )
        setLoading(false)
        setFetching(false)
      })
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') setLoading(false)
        setFetching(false)
      })
  }

  const handlePageUpdate = (incremetor: number): void => {
    setFilters((oldFilters) => {
      return {
        ...oldFilters,
        page: oldFilters.page + incremetor,
      }
    })
  }

  const pageTitme = useMemo(() => {
    if (router.asPath === '/b') return organizationSetting.bucket_plural_name
    if (boardSlug?.length) {
      return buckets?.find((b) => b.name === boardSlug)?.display_name
    }
    return null
  }, [boardSlug, buckets, router.asPath, organizationSetting])

  useEffect(() => {
    if (!noFetch) fetchPosts()
  }, [filters])

  useEffect(() => {
    if (updateOnFilters) return
    const updatedFilters = {
      ..._filters,
      ...router.query,
      page: 1,
    }
    setFilters(updatedFilters)
  }, [router.query])

  useEffect(() => {
    if (!updateOnFilters || !_filters) return
    setFilters({
      ..._filters,
      page: 1,
    })
  }, [_filters])

  useEffect(() => {
    if (!_searchedPosts) return setSearchedPosts(null)
    const resultedPosts = sortSearchedPosts(_searchedPosts, filters)
    return setSearchedPosts(resultedPosts)
  }, [_searchedPosts])

  useEffect(() => {
    if (_searchedPosts?.length) {
      const resultedPosts = sortSearchedPosts(_searchedPosts, filters)
      setSearchedPosts(resultedPosts)
    }
  }, [filters])

  useEffect(() => {
    EventEmitter.subscribe('ENTITY_UPDATE', handlePostsUpdate)
    return () => {
      EventEmitter.unsubscribe('ENTITY_UPDATE', handlePostsUpdate)
    }
  }, [])

  const renderPost = (post: IPost) => <UserPostItem key={post.id} post={post} />

  const renderPosts = () => {
    if (searchedPosts) {
      if (searchedPosts.length) {
        return removeDuplicatesInArray(searchedPosts, 'id').map(renderPost)
      }
      return (
        <div className='flex flex-col'>
          <EmptyState
            icon={Binoculars}
            isFullScreen={false}
            className='!h-3/5'
            description={t('messages.noSearchedPosts.message')}
          />
        </div>
      )
    }

    if (loading) return <UserPostListLoader />
    if (!posts.length)
      return (
        emptyState || (
          <div className='flex flex-col'>
            <EmptyState
              title={t('messages.noPosts.title')}
              description={t('messages.noPosts.message')}
              className='!h-3/5'
              actionComponent={
                <NewPostButton boardId={activeBoard?.id || undefined} />
              }
            />
          </div>
        )
      )

    return (
      <>
        {!!pinnedPosts.length && (
          <>
            <div className='my-2 flex flex-col'>
              <LineHeader title={t('labels.pinned')} color='default' pinned />
              {pinnedPosts.map(renderPost)}
            </div>
          </>
        )}
        <div
          className={clsx('flex flex-col', {
            'border-t border-gray5': !!pinnedPosts.length,
          })}
        >
          {unPinnedPosts.map(renderPost)}
        </div>
      </>
    )
  }

  return (
    <>
      {renderPosts()}
      {pageTitme && <PageTitle title={pageTitme} />}
      {searchedPosts ? (
        <>
          <InfiniteScroll
            count={searchedPostsEstimatedCount}
            loaderComponent={<UserPostListLoader count={3} />}
            showIf={
              Boolean(searchedPostsEstimatedCount > searchedPosts.length) &&
              !loading
            }
            canFetchMore={searchedPostsEstimatedCount > searchedPosts.length}
            onIntersect={() => {
              onLoadMoreSearch?.()
            }}
          />
        </>
      ) : (
        <InfiniteScroll
          count={posts.length}
          loaderComponent={<UserPostListLoader count={3} />}
          showIf={
            Boolean(posts.length && !searchedPosts) && !loading && !fetching
          }
          canFetchMore={canFetchMore}
          onIntersect={() => handlePageUpdate(1)}
        />
      )}
    </>
  )
}
